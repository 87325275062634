import { Controller } from "stimulus"

let fakeMixpanel = class {
  track(...args) {
    // Do nothing
    console.log('[MIXPANEL] tracking', args)
  }
}

export default class extends Controller {
  static targets = []

  connect() {
    if (this.data.get("key")) {
      this.enableMixpanelTracking()
    } else {
      window.mixpanel = new fakeMixpanel
    }
  }

  enableMixpanelTracking() {
    const mixpanel = require('mixpanel-browser')

    mixpanel.init(this.data.get("key"));
    mixpanel.identify(this.data.get("id"))

    window.mixpanel = mixpanel
  }
}